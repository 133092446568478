//
//
//
//
//
//

export default {
  props: {
    blok: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    bloks () {
      return this.blok?.content?.bloks || []
    }
  }
}
